


























































































import { defineComponent, onMounted, Ref, ref, watch } from '@vue/composition-api'
import { useActions, useState } from '@/shared/mixins/helpers'
import { useI18n } from 'vue-i18n-composable'
import apolloClient from '@/shared/services/ApolloCLientAPI'
import Table from '@/shared/components/Table.vue'
import { TableHeaderDTO } from '@/dto/tableHeaderDTO'
import { AuditRecordDTO } from '@/dto/backend-response/audittrailDTO'
import utils from '@/shared/mixins/utils'
import audittrailData from '@/assets/data/audittrail-data.json'

export default defineComponent({
    name: 'Audittrail',
    components: { Table },
    setup() {
        const auditRecordList = ref([])
        const { t } = useI18n()
        const { language } = useState(['language'])
        const tableHeaders: Ref<Array<TableHeaderDTO>> = ref([])
        const loading = ref(false)
        const { SET_OPEN_MENU } = useActions(['SET_OPEN_MENU'])
        const { SET_LEFT_MENU_SIDEBAR } = useActions(['SET_LEFT_MENU_SIDEBAR'])
        const { menuOpen } = useState(['menuOpen'])
        const visibleLeft = ref(false)
        const tableCmp = ref(null)
        const filterCount = ref(0)
        const filteredRecordCount = ref(0)
        const searchValue = ref('')
        const entityDescription = ref('')

        // set table headers
        const setTableHeaders = (language: string) => {
            tableHeaders.value = [
                {
                    header: t('AUDIT_TRAIL_ENTITY_DESCRIPTION', language),
                    sort: true,
                    fieldName: 'entity_description',
                },
                {
                    header: 'Tracking ID',
                    sort: true,
                    fieldName: 'tracking_id',
                },
                {
                    header: t('AUDIT_TRAIL_ENTITY_CHANGE_DATE', language),
                    sort: true,
                    fieldName: 'change_date',
                    type: 'date',
                    timestamp: true,
                },
                {
                    header: t('AUDIT_TRAIL_ENTITY_CHANGE_USER', language),
                    sort: true,
                    fieldName: 'fChangedBy',
                },
                {
                    header: t('AUDIT_TRAIL_ENTITY_CHANGED_FIELD', language),
                    sort: true,
                    fieldName: 'fChangedField',
                },
                {
                    header: t('AUDIT_TRAIL_ENTITY_OLD_VALUE', language),
                    sort: true,
                    fieldName: 'fOldValue',
                },
                {
                    header: t('AUDIT_TRAIL_ENTITY_NEW_VALUE', language),
                    sort: true,
                    fieldName: 'fNewValue',
                },
            ]
        }

        const auditTrailEntities = [
            { value: "ACTIONPLAN", name: t("ACTION_PLAN", language) },
            { value: "ASSET", name: t("ASSET_CONTROL_LABEL", language) },
            { value: "CONTROL", name: t("CONTROL", language) },
            { value: "CONTROL_ASSESSMENT", name: t("CONTROL_ASSESSMENT_TITLE", language) },
            { value: "EXECUTION_GUIDE", name: t("EXECUTION_GUIDE", language) },
            { value: "HELP_PAGE", name: t("HELP_PAGE", language) },
            { value: "MITIGATION", name: t("MITIGATION", language) },
            { value: "MSR", name: t("MSR", language) },
            { value: "MSR_ASSESSMENT", name: t("MSR_ASSESSMENT_TITLE", language) },
            { value: "MSR_ACTIONPLAN", name: t("MSR_ACTION_PLAN_TITLE", language) },
            { value: "NORM", name: t("HELP_NORM_DOC", language) },
            { value: "NORM_TEMPLATE_DOCUMENT", name: t("NORM_TEMPLATE_DOCUMENT", language) },
            { value: "ORGANISATION_CONFIG", name: t("HELP_ORGANISATION_SETTINGS_DOC", language) },
            { value: "RISK_ASSESSMENT", name: t("RISK_ASSESSMENT_TITLE", language) },
            { value: "THREAT", name: t("THREAT", language) },
            { value: "VULNERABILITY", name: t("VULNERABILITY", language) }
        ]

        const toTableFormat = (tempAuditrecords: Array<AuditRecordDTO>) => {
            auditRecordList.value = []
            tempAuditrecords.map((auditrecord) => {
                let ar = {
                    entityDescription: '',
                    fChangedBy: '-',
                    changeDate: '',
                    fChangedField: '-',
                    fOldValue: '-',
                    fNewValue: '-',
                }
                // creating a copy of object to hold formatted and extra attributes
                ar = Object.create(auditrecord)
                if (auditrecord.change_user != null) {
                    ar.fChangedBy = auditrecord.change_user.username
                } else {
                    ar.fChangedBy = '-'
                }
                if (auditrecord.changes_with_previous.length > 0) {
                    ar.fChangedField = auditrecord.changes_with_previous[0].field_description
                    ar.fOldValue = auditrecord.changes_with_previous[0].field_oldvalue
                    ar.fNewValue = auditrecord.changes_with_previous[0].field_newvalue
                } else {
                    ar.fChangedField = '-'
                    ar.fOldValue = '-'
                    ar.fNewValue = '-'
                }
                auditRecordList.value.push(ar)
            })
            filteredRecordCount.value = auditRecordList.value.length
        }

        const getAuditTrail = async () => {
            loading.value = true
            const getAuditTrailQuery = `
                query {
                    audittrail {
                        request_date,
                        auditrecords (filter: {entity: ${entityDescription.value}}) {
                            entity_description,
                            change_user {
                                username
                            },
                            change_date,
                            changes_with_previous {
                                field_description,
                                field_oldvalue,
                                field_newvalue
                            }
                            tracking_id
                        }
                    }
                }`
            let result
            try {
                result = await apolloClient.getGraphqlData(getAuditTrailQuery)
                loading.value = false
            } catch (err) {
                loading.value = false
                throw Error('Error while retrieving audit trail data')
            }
            if (result) {
                toTableFormat(result.data.audittrail.auditrecords)
            }
        }

        // display filter section
        const viewFilterSection = () => {
            tableCmp.value.setTableHeight()
        }

        // set filters count
        const setCount = (count) => {
            filterCount.value = count
        }

        // set searched records count
        const searchCount = (count) => {
            filterCount.value = 0
            filteredRecordCount.value = count
        }
        // update table records count
        const updateRecordCount = (recordsCount) => {
            filteredRecordCount.value = recordsCount
        }

        // watch for language change from store and update table headers by calling setTableHeaders
        watch(
            language,
            (newValue: string) => {
                setTableHeaders(newValue)
            },
            {
                immediate: true,
            }
        )

        onMounted(() => {
            setTableHeaders(language)
        })

        return {
            auditRecordList,
            t,
            language,
            tableHeaders,
            loading,
            utils,
            menuOpen,
            visibleLeft,
            SET_OPEN_MENU,
            SET_LEFT_MENU_SIDEBAR,
            audittrailData,
            viewFilterSection,
            setCount,
            filterCount,
            filteredRecordCount,
            updateRecordCount,
            searchValue,
            searchCount,
            tableCmp,
            entityDescription,
            auditTrailEntities,
            getAuditTrail
        }
    },
})
